import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap, BehaviorSubject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { Tokens } from '../models/auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {}
  user = new BehaviorSubject<any>(null);
  refreshTimeout!: NodeJS.Timeout;

  signIn(data: {
    email: string;
    password: string;
    current2FaCode: string | null;
  }): Observable<Tokens> {
    return this.http.post<Tokens>(`/tokens`, data).pipe(
      tap((resp: Tokens) => {
        this.handleAuth(resp);
      })
    );
  }

  autoSignIn() {
    const token = JSON.parse(<string>localStorage.getItem('token'));
    if (!token) {
      return;
    }
    if (window.location.pathname === '/') {
      this.handleAuth(token);
    } else {
      this.handleAuth(token, false);
    }
  }

  private handleAuth(data: Tokens, withRedirect = true) {
    localStorage.setItem('token', JSON.stringify(data));
    const jwtHelper = new JwtHelperService();
    const userData = jwtHelper.decodeToken(data.token);
    this.startRefreshTimer(userData.exp * 1000, data);
    this.user.next(userData);
    if (withRedirect) {
      this.router.navigate(['/dashboard/orders']);
    }
  }

  refreshToken(tokens: {
    token: string;
    refreshToken: string;
  }): Observable<Tokens> {
    return this.http.post<Tokens>(`/tokens/refresh`, {
      token: tokens.token,
      refreshToken: tokens.refreshToken,
    });
  }

  private startRefreshTimer(expTime: number, tokens: Tokens) {
    const expires = new Date(expTime);

    const timeout = expires.getTime() - Date.now() - 60000;
    this.refreshTimeout = setTimeout(
      () =>
        this.refreshToken(tokens).subscribe({
          next: (resp) => {
            this.handleAuth(resp, false);
          },
        }),
      timeout
    );
  }

  getTokenData() {
    return localStorage.getItem('token');
  }

  getUserRole(): string {
    const jwtHelper = new JwtHelperService();
    const token = JSON.parse(<string>localStorage.getItem('token'));
    if (token) {
      const userData = jwtHelper.decodeToken(token.token);
      return userData['base.role'];
    }
    return '';
  }

  public get userData() {
    return this.user.value;
  }

  logout() {
    localStorage.removeItem('token');
    this.user.next(null);
    this.router.navigate(['']);
  }
}
