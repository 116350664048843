import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FilterOptions, LogsSearchOptions } from '../models/filter.model';
import {
  Order,
  OrderDetails,
  ShipperBuyer,
  HealthCertificateDetails,
  OrderWithStages,
  OrderDynamicFieldValues,
  OrderLogs,
} from '../models/order.model';
import { OrderStage } from '../enums/order-stage';
import { ResponseData } from '../models/helpers.model';
import { OrderDocScan, OrderDocumentForStages } from '../models/order-document';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(private http: HttpClient) {}

  getOrders(filterOptions: FilterOptions): Observable<ResponseData<Order>> {
    return this.http.post<ResponseData<Order>>(`/orders/filter`, filterOptions);
  }

  getOrdersSummary(filterOptions: FilterOptions): Observable<number> {
    return this.http.post<number>(`/orders/filter/summary`, filterOptions);
  }

  getOrder(id: string): Observable<OrderWithStages> {
    return this.http.get<OrderWithStages>(`/orders/${id}`);
  }

  getLogs(id: string, searchData: LogsSearchOptions): Observable<OrderLogs> {
    return this.http.post<OrderLogs>(
      `/orders/${id}/change-logs/filter`,
      searchData
    );
  }

  getUsersForLogs(id: string): Observable<User[]> {
    return this.http.get<User[]>(`/orders/${id}/change-logs/users`);
  }

  setOrderStage(
    id: string,
    data: { stage: OrderStage | null }
  ): Observable<string> {
    return this.http.put<string>(`/orders/${id}/set-stage`, data, {
      responseType: 'text' as 'json',
    });
  }

  setOrderIsNew(id: string, data: { isNew: boolean }): Observable<string> {
    return this.http.put<string>(`/orders/${id}/set-is-new`, data, {
      responseType: 'text' as 'json',
    });
  }

  setSipper(id: string, data: ShipperBuyer): Observable<string> {
    return this.http.put<string>(`/orders/${id}/set-shipper`, data, {
      responseType: 'text' as 'json',
    });
  }

  setBuyer(id: string, data: ShipperBuyer): Observable<string> {
    return this.http.put<string>(`/orders/${id}/set-buyer`, data, {
      responseType: 'text' as 'json',
    });
  }

  setConsignee(id: string, data: { consigneeId: string }): Observable<string> {
    return this.http.put<string>(`/orders/${id}/set-consignee`, data, {
      responseType: 'text' as 'json',
    });
  }

  setDetails(id: string, data: OrderDetails): Observable<string> {
    return this.http.put<string>(`/orders/${id}/set-details`, data, {
      responseType: 'text' as 'json',
    });
  }

  setSpecialInstruction(
    id: string,
    data: { specialInstruction: string }
  ): Observable<string> {
    return this.http.put<string>(
      `/orders/${id}/set-special-instruction`,
      data,
      {
        responseType: 'text' as 'json',
      }
    );
  }

  setAdditionalCertification(
    id: string,
    data: { additionalCertification: string }
  ): Observable<string> {
    return this.http.put<string>(
      `/orders/${id}/set-additional-certification`,
      data,
      {
        responseType: 'text' as 'json',
      }
    );
  }

  setCancel(id: string, canceledReason: string): Observable<string> {
    return this.http.put<string>(
      `/orders/${id}/set-cancel`,
      { canceledReason },
      {
        responseType: 'text' as 'json',
      }
    );
  }

  setForwardStatementAttachment(
    id: string,
    data: { forwardStatementAttachmentId: string }
  ): Observable<string> {
    return this.http.put<string>(
      `/orders/${id}/set-forward-statement-attachment`,
      data,
      {
        responseType: 'text' as 'json',
      }
    );
  }

  setHealthCertificateDetails(
    id: string,
    data: Partial<HealthCertificateDetails>
  ): Observable<string> {
    return this.http.put<string>(
      `/orders/${id}/set-health-certificate-details`,
      data,
      {
        responseType: 'text' as 'json',
      }
    );
  }

  setDynamicFieldsValue(
    id: string,
    data: { fields: Array<OrderDynamicFieldValues>; fromStage: OrderStage }
  ): Observable<boolean> {
    return this.http.put<boolean>(`/orders/${id}/fields`, data);
  }

  setCertificateNumber(
    id: string,
    data: { number: string; reason: string }
  ): Observable<string> {
    return this.http.put<string>(`/orders/${id}/set-certificate-number`, data, {
      responseType: 'text' as 'json',
    });
  }

  setOrderNotificationIsSeen(
    orderId: string,
    notificationId: string
  ): Observable<string> {
    return this.http.put<string>(
      `/orders/${orderId}/notifications/${notificationId}/set-is-seen`,
      {},
      {
        responseType: 'text' as 'json',
      }
    );
  }

  setOrderRelease(
    id: string,
    data: { id: string; userId: string }
  ): Observable<string> {
    return this.http.put<string>(`/orders/${id}/set-release`, data, {
      responseType: 'text' as 'json',
    });
  }

  getOrderDocuments(
    id: string
  ): Observable<HttpResponse<OrderDocumentForStages[]>> {
    return this.http.get<OrderDocumentForStages[]>(`/orders/${id}/documents`, {
      observe: 'response',
    });
  }

  getOrderDocumentPreview(orderId: string, docId: string): Observable<string> {
    return this.http.get<string>(
      `/orders/${orderId}/documents/${docId}/preview`
    );
  }

  setOrderDocumentStatus(
    orderId: string,
    docId: string,
    data: { isApproved: boolean; rejectReason: string | null }
  ): Observable<OrderDocumentForStages> {
    return this.http.put<OrderDocumentForStages>(
      `/orders/${orderId}/documents/${docId}/set-status`,
      data
    );
  }

  uploadDocumentScan(
    orderId: string,
    docId: string,
    scanId: string
  ): Observable<string> {
    let params = new HttpParams().set('attachmentId', scanId);
    return this.http.post<string>(
      `/orders/${orderId}/documents/${docId}/scan`,
      {},
      {
        params: params,
        responseType: 'text' as 'json',
      }
    );
  }

  getOrderPhotos(orderId: string): Observable<string[]> {
    return this.http.get<string[]>(`/orders/${orderId}/photos`);
  }

  uploadOrderPhotos(
    orderId: string,
    data: { ids: Array<string> }
  ): Observable<string> {
    return this.http.post<string>(`/orders/${orderId}/photos`, data, {
      responseType: 'text' as 'json',
    });
  }

  getDocumentScans(orderId: string): Observable<OrderDocScan[]> {
    return this.http.get<OrderDocScan[]>(`/orders/${orderId}/scans`);
  }

  uploadDocumentScans(
    orderId: string,
    data: { attachmentIds: Array<string> }
  ): Observable<string> {
    return this.http.post<string>(`/orders/${orderId}/scans`, data, {
      responseType: 'text' as 'json',
    });
  }

  cmpInventoryApproveStage3(id: string): Observable<string> {
    return this.http.put<string>(
      `/orders/${id}/stage3/cmp-inventory-approve`,
      {},
      { responseType: 'text' as 'json' }
    );
  }

  customerApproveStage3(id: string): Observable<string> {
    return this.http.put<string>(
      `/orders/${id}/stage3/customer-approve`,
      {},
      { responseType: 'text' as 'json' }
    );
  }

  cmpInventoryApprove(id: string): Observable<string> {
    return this.http.put<string>(
      `/orders/${id}/stage4/cmp-inventory-approve`,
      {},
      { responseType: 'text' as 'json' }
    );
  }

  customerApprove(id: string, comment: string): Observable<string> {
    let params = new HttpParams().set('comment', comment);
    return this.http.put<string>(
      `/orders/${id}/stage4/customer-approve`,
      {},
      { params: params, responseType: 'text' as 'json' }
    );
  }

  sendNotificationAboutChanges(
    id: string,
    stage: OrderStage
  ): Observable<string> {
    return this.http.put<string>(
      `/orders/${id}/notifications/stage/${stage}/ready-to-review`,
      {},
      { responseType: 'text' as 'json' }
    );
  }
}
